import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../../components/Layout';
import { Section } from '../../components/Section';
import { Calculator, CalculatorAside } from '../../components/Calculator';
import Testimonials from '../../components/Testimonials';
import SectionTop from '../../components/SectionTop';
import staticData from '../index-data.json';
import './index.scss';

const EssayWritingHelp = () => (
  <Layout className="IndexPage EssayWritingHelp">
    <Helmet>
      <title>Essay Help You Can Fully Rely On | Ca.CustomWritings.com</title>
      <meta
        name="description"
        content="Looking for essay writing help? We’re here for you! Our professionals are trained to provide high-quality assistance with any kind of college writing. In addition, our client-oriented guarantees are always in place protecting your rights."
      />
      <link rel="canonical" href="https://ca.customwritings.com/essay-writing-help.html" />
      <link
        rel="alternate"
        hrefLang="en"
        href="https://www.customwritings.com/essayhelp.html"
      />
      <link rel="alternate" hrefLang="en-ca" href="https://ca.customwritings.com/essay-writing-help.html" />
      <link rel="alternate" hrefLang="x-default" href="https://www.customwritings.com/essayhelp.html" />
    </Helmet>

    <SectionTop>
      <h1 className="Section-top__content__title">Essay writing help for Canadian students</h1>
      <span className="Section-top__content__subtitle" />
      <span className="Section-top__content__description">Get help with your homework at an affordable price</span>
      <a rel="nofollow" href="https://www.customwritings.com/dashboard/orders/create/" className="button">Buy an essay</a>
    </SectionTop>

    <Section className="Section Section-seo">
      <h2 className="Section__heading Section__heading--bubble-1">The most reliable source of essay help online for Canadian students</h2>
      <p>
        It doesn’t matter whether you’re good at a particular subject or not, or if you tend to have problems with
        writing on the whole—even the most skilled writers occasionally need essay help. It may be the topic of a
        particular assignment, emotional problems, or a general lack of time that forces an academic work to go on the
        back burner. Specifics don’t matter, because one way or another, you’re eventually going to need essay writing
        help, and it’s a good idea to have at least one reliable source of college essay help on hand for just such an
        occasion. However, if you’re studying in Canada, you’re going to have additional concerns. You can’t just
        visit any writing website and say, “I need help with my essay.” Most agencies working in this industry primarily deal with clients from the US, and often don’t know the specifics of the Canadian educational system or its essay writing conventions. When you look for essay help online, you have to choose a service that’s used to helping Canadian customers—like CustomWritings.com.
      </p>
    </Section>

    <Section className="Section-calculator Section--bg-blue">
      <h2 className="Section__heading">Calculate the price of your paper here</h2>
      <div className="calculator-wrapper">
        <Calculator />
        <CalculatorAside />
      </div>
    </Section>

    <Section className="Section Section-seo">
      <h2 className="Section__heading Section__heading--bubble-2">I need urgent help with my essay! Can you assist me?</h2>
      <p>
        The short answer is “yes”. The long answer is that we can help you with virtually any task, but first, you
        have to fill in the order form and provide as many details about your assignment as possible. Alternatively,
        you can contact our customer support and say, “Help me write my college essay”—he/she will then lead you
        through the order process and collect all the necessary data. After that, we’ll go through our entire database
        of writers and choose the one who’s the most suitable to work on your specific order, considering his/her
        experience, knowledge, and writing style. It’s much better than hiring a random freelancer and saying, “Help
        me write an essay.” When you receive online essay help from individuals, you have no way of knowing if the
        person you hired knows his/her job. When you deal with CustomWritings.com, you get the best essay help
        specialist out of dozens and dozens of experienced writers.
      </p>
      <h2 className="Section__heading Section__heading--bubble-3">Is it possible to get free online essay help?</h2>
      <p>
        Both students in high school and those in college often try to get essay help online free of charge.
        Unfortunately, there’s no such thing, and even if you find free online essay help, you should be wary of the
        motives of those offering it. Writing a good academic assignment is a difficult and time-consuming job, and no
        skilled helper is going to do it without remuneration. In other words, when you say, “Help me write an essay
        for free,” you can’t seriously expect somebody to write it for you. The best you’re going to get is a
        pre-written text from some online database, most likely having little to do with the topic you’re interested
        in. If you need help writing essay, you have to be ready to pay for it. Fortunately, we at CustomWritings.com
        understand that students can rarely afford to spend much on university essay help, and price our services
        accordingly. Our fees start at $10 per page and mostly depend on the urgency of a task. If you place an order
        early enough, you can get even the most complex English essay help at a relatively low price.
      </p>
    </Section>

    <Section className="Section Section-testimonials Section--bg-blue">
      <h2 className="Section__heading">What customers say about us</h2>
      <Testimonials testimonials={staticData.testimonials} />
    </Section>

    <Section className="Section Section-seo">
      <h2 className="Section__heading Section__heading--bubble-1">Why else you should consider CustomWritings.com</h2>
      <p>
        “There are dozens upon dozens of services offering essay homework help, and some of them have pretty good
        reviews. Why should I ask you specifically to help me write my essay?” Most first-time visitors ask us this or
        something very similar, and we’re used to answering this question. Here are some reasons you may find
        compelling:
      </p>
      <ul>
        <li>
          We work around the clock. Visit our website whenever it’s convenient for you, say, “Help me, essay writing
          is not my strong suit,” and we’ll immediately set about looking for a suitable specialist for you.
        </li>
        <li>
          Our service is universal. Whether you want us to write or edit our essay, or need some essay outline help,
          just say the word.
        </li>
        <li>
          We work with clients of all academic levels. Whether you need high school help or are studying at
          university, simply specify what kind of writer you need, and we’ll arrange it.
        </li>
        <li>
          We offer many customization options. If you need help writing an essay and think you are going to need
          copies of the sources used by the author, simply ask for them. Want to know more about the writer we
          assigned to you? Ask for examples of his/her prior work. Want to break the order into parts and receive them
          as soon as they are finished? Choose Progressive Delivery when you order essay writing help online.
        </li>
      </ul>
      <p>
        Don’t wait any longer—simply say, “Help me with my essay,” and we’ll apply every effort to make your life
        easier!
      </p>
    </Section>
  </Layout>
);

export default EssayWritingHelp;
